import { render } from "./bloodList.vue?vue&type=template&id=3036f1e6&scoped=true"
import script from "./bloodList.vue?vue&type=script&lang=js"
export * from "./bloodList.vue?vue&type=script&lang=js"

import "./bloodList.vue?vue&type=style&index=0&id=3036f1e6&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-3036f1e6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3036f1e6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3036f1e6', script)) {
    api.reload('3036f1e6', script)
  }
  
  module.hot.accept("./bloodList.vue?vue&type=template&id=3036f1e6&scoped=true", () => {
    api.rerender('3036f1e6', render)
  })

}

script.__file = "src/views/home/list/bloodList.vue"

export default script